import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  controllerrefreshapi,
  devicerefreshapi,
  motordata,
  SimDropdownService,
  UpdateAMCReportService,
  viewdeviceapi,
} from "../../../Service/ApiService";
import Toggle from "../../ToggleButton";
import { ImSpinner11 } from "react-icons/im";
import { toast } from "react-hot-toast";
import Changestatus from "../../../Modalcomponents/Changestatus";
import Loader from "../../Loader";
import moment from "moment";

import { MdLocationOn } from "react-icons/md";
import productionstyle from "../Productionunit/production.module.css";
import dayjs from "dayjs";
import motorstyle from "../ViewController/motorcontroller.module.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import commonstyle from "../../../Assets/css/CommonStyle.module.css";
import {
  Float_Validation,
  getCatchMsg,
  JSONtoformdatawithempty,
} from "../../../Utilities/Methods";

import CommonSelect from "../../CommonComponents/CommonSelect";
import { useFormik } from "formik";
import * as Yup from "yup";
import CommonInput from "../../CommonComponents/CommonInput";
import CommonDateTimePicker from "../../CommonComponents/CommonDatePicker";
import CommonSwitch from "../../CommonComponents/CommonSwitch";
import ConfirmationModal from "../../../Modalcomponents/ConfirmationModal";
import { ExportedImages } from "../../../Utilities/ImageExport";

const validationSchema = Yup.object().shape({
  amc_start_date: Yup.string().when("sim_id", (sim_id, schema) => {
    if (sim_id) return schema.required("Start Date is required");
    return schema;
  }),
  amc_cost: Yup.string().when("sim_id", (sim_id, schema) => {
    if (sim_id) return schema.required("Charges is required");
    return schema;
  }),
  validity: Yup.string().when("sim_id", (sim_id, schema) => {
    if (sim_id) return schema.required("Validity is required");
    return schema;
  }),
});

export default function ProductionControllerDetails() {
  let abortcontroller = undefined;
  const [modal, setmodal] = useState(false);
  const [data, setdata] = useState();
  const [loader, setloader] = useState(false);
  const [isloading, setloading] = useState(false);
  const [deviceid, setdeviceid] = useState();
  const [spinnerindevice, setspinnerindevice] = useState(false);
  let navigate = useNavigate();
  const [editAMC, setEditAMC] = useState(false);
  const [amcchangeStatus, setAmcchangeStatus] = useState({
    type: 0,
    show: false,
  });
  const [type, settype] = useState({
    requesttypeOne: 1,
    requesttypeTwo: 2,
    requesttypeThree: 3,
    requesttypeFour: 4,
    requesttypeFive: 5,
    requesttypeSix: 6,
  });
  const [locationtype, setlocation] = useState(0);
  const [buttongreyid, setbuttongreyid] = useState(0);
  const [SimList, setSimList] = useState([]);
  let productiondata = JSON.parse(sessionStorage.getItem("details"));

  const { values, setFieldValue, errors, touched, handleSubmit, setValues } =
    useFormik({
      initialValues: {
        sim_id: null,
        amc_start_date: "",
        amc_cost: "",
        validity: "",
        amc_msg: "",
        remarks: "",
        status: "",
        amc_status: "",
        inactivate_delay: "",
        // deactivate_delay: "",
      },
      validationSchema,
      onSubmit: (values) => {
        UpdateAMCReportData(values);
      },
    });

  const UpdateAMCReportData = (values) => {
    // setloader(true);
    let finalObj = {
      controller_id: productiondata.controller_id || "",
      device_id: productiondata.device_id || "",
      sim_id: values?.sim_id || "",
      amc_start_date: values?.amc_start_date || "",
      amc_cost: values?.amc_cost || "",
      validity: values?.validity || "",
      amc_msg: values?.amc_msg || "",
      remarks: values?.remarks || "",
      status: values?.status === 1 ? 1 : 0,
      amc_status: values?.amc_status === 1 ? 1 : 0,
      inactivate_delay: values?.inactivate_delay || "",
      // deactivate_delay: values?.deactivate_delay || "",
    };
    if (productiondata.controller_id) {
      delete finalObj.device_id;
    } else {
      delete finalObj.controller_id;
    }

    UpdateAMCReportService(JSONtoformdatawithempty(finalObj))
      .then(({ data }) => {
        if (data.status === 1) {
          toast.success(data.msg);
          setEditAMC(false);
          toviewcontroller();
        } else {
          toast.error(data.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setloader(false);
      });
  };

  const toviewcontroller = () => {
    if (productiondata?.product_type === 1) {
      setloader(true);

      motordata(productiondata?.controller_id)
        .then(({ data }) => {
          setdata(data);
          setValues({
            sim_id: data.sim_id || null,
            amc_start_date: data.amc_start_date || "",
            amc_cost: data.amc_cost || "",
            validity: data.validity || "",
            amc_msg: data.amc_msg || "",
            remarks: data.remarks || "",
            status: data.status || "",
            amc_status: data.amc_status || "",
            inactivate_delay: data.inactivate_delay || "",
            // deactivate_delay: data.deactivate_delay || "",
          });
        })
        .catch((err) => {
          if (err?.response?.data?.detail?.[0]?.msg) {
            toast(err?.response?.data?.detail?.[0]?.msg, { type: "error" });
          } else if (err?.response?.data?.detail) {
            toast(err?.response?.data?.detail, { type: "error" });
          } else {
            toast("Something went wrong!!", { type: "error" });
          }
        })
        .finally(() => setloader(false));
    } else if (productiondata?.product_type === 2) {
      setloader(true);

      viewdeviceapi(productiondata?.device_id)
        .then(({ data }) => {
          setdata(data);
          setValues({
            sim_id: data.sim_id || null,
            amc_start_date: data.amc_start_date || "",
            amc_cost: data.amc_cost || "",
            validity: data.validity || "",
            amc_msg: data.amc_msg || "",
            remarks: data.remarks || "",
            status: data.status || "",
            amc_status: data.amc_status || "",
            inactivate_delay: data.inactivate_delay || "",
            // deactivate_delay: data.deactivate_delay || "",
          });
        })
        .catch((err) => {
          if (err?.response?.data?.detail?.[0]?.msg) {
            toast(err?.response?.data?.detail?.[0]?.msg, { type: "error" });
          } else if (err?.response?.data?.detail) {
            toast(err?.response?.data?.detail, { type: "error" });
          } else {
            toast("Something went wrong!!", { type: "error" });
          }
        })
        .finally(() => setloader(false));
    }
  };

  // const changesstatus = () => {
  //   if (productiondata?.controller_type) {
  //     setloader(true);

  //     let formdata = {
  //       controller_name: data?.controller_name,
  //       latitude: data?.latitude,
  //       longitude: data?.longitude,
  //       msg: data?.msg,
  //       status: data?.status,
  //     };
  //     editcontroller(productiondata?.controller_id, formdata)
  //       .then(({ data }) => {
  //         toast(data, { type: "success" });
  //         toviewcontroller();
  //         setmodal(false);
  //       })
  //       .catch()
  //       .finally(() => setloader(false));
  //   } else {
  //     if (productiondata?.device_type === 4) {
  //       setloader(true);

  //       let formdata = {
  //         device_name: data?.device_name,
  //         latitude: data?.latitude,
  //         longitude: data?.longitude,
  //         msg: data?.msg,
  //         status: data?.status,
  //         dim: data?.dim_level,
  //       };
  //       editdevice(formdata, productiondata?.device_id)
  //         .then(({ data }) => {
  //           toviewcontroller();
  //           setmodal(false);
  //           toast(data, { type: "success" });
  //         })
  //         .catch()
  //         .finally(() => setloader(false));
  //     } else if (productiondata?.device_type === 1) {
  //       setloader(true);

  //       let formdata = {
  //         device_name: data?.device_name,
  //         low_limit: data?.low_limit,
  //         high_limit: data?.high_limit,
  //         edit_action: 0,
  //         tank_height: data?.tank_height,
  //         eb_number: data?.eb_number,
  //         latitude: data?.latitude,
  //         longitude: data?.longitude,
  //         msg: data?.msg,
  //         status: data?.status,
  //         lora_status: data?.lora_status,
  //       };
  //       editdevice(formdata, productiondata?.device_id)
  //         .then(({ data }) => {
  //           toviewcontroller();

  //           toast(data, { type: "success" });

  //           setmodal(false);
  //         })
  //         .catch()
  //         .finally(() => setloader(false));
  //     } else if (productiondata?.device_type == 2) {
  //       setloader(true);

  //       let formdata = {
  //         device_name: data?.device_name,
  //         usage_limit: data?.usage_limit,
  //         eb_number: data?.eb_number,
  //         latitude: data?.latitude,
  //         longitude: data?.longitude,
  //         msg: data?.msg,
  //         status: data?.status,
  //         lora_status: 0,
  //       };
  //       editdevice(formdata, productiondata?.device_id)
  //         .then(({ data }) => {
  //           toviewcontroller();
  //           setmodal(false);
  //           toast(data, { type: "success" });
  //         })
  //         .catch((err) => {
  //           if (err?.response?.data?.detail?.[0]?.msg) {
  //             toast(err?.response?.data?.detail?.[0]?.msg, { type: "error" });
  //           } else if (err?.response?.data?.detail) {
  //             toast(err?.response?.data?.detail, { type: "error" });
  //           } else {
  //             toast("Something went wrong!!", { type: "error" });
  //           }
  //         })
  //         .finally(() => setloader(false));
  //     } else if (productiondata?.device_type === 3) {
  //       setloader(true);

  //       let formdata = {
  //         device_name: data?.device_name,
  //         eb_number: data?.eb_number,
  //         latitude: data?.latitude,
  //         longitude: data?.longitude,
  //         msg: data?.msg,
  //         status: data?.status,
  //       };
  //       editdevice(formdata, productiondata?.device_id)
  //         .then(({ data }) => {
  //           toviewcontroller();
  //           setmodal(false);
  //           toast(data, { type: "success" });
  //         })
  //         .catch()
  //         .finally(() => setloader(false));
  //     }
  //   }
  // };

  const Changebutton = (togglestatus) => {
    //controller button
    if (abortcontroller) {
      abortcontroller.abort("error cancelled");
    }

    abortcontroller = new AbortController();

    if (productiondata?.product_type === 1) {
      // setloader(true);
      let formData = new FormData();
      formData.append("controller_id", productiondata?.controller_id);
      formData.append("controller_type", productiondata?.controller_type);
      formData.append("request_type", togglestatus ? 3 : 4);

      controllerrefreshapi(formData, abortcontroller)
        .then(({ data }) => {
          setTimeout(() => {
            toviewcontroller();
            setbuttongreyid(0);
          }, 5000);
        })
        .catch((err) => {})
        .finally(() => setloader(false));
    } //device button
    else if (productiondata?.device_type === 3) {
      // setloader(true);
      let formData = new FormData();
      formData.append("device_id", productiondata?.device_id);
      formData.append("device_type", productiondata?.device_type);
      formData.append("request_type", togglestatus ? 3 : 4);

      devicerefreshapi(formData, abortcontroller)
        .then(({ data }) => {
          setTimeout(() => {
            toviewcontroller();
            setbuttongreyid(0);
          }, 5000);
        })
        .catch((err) => {});
      // .finally(() => setloader(false));
    } else if (productiondata?.device_type === 4) {
      // setloader(true);
      let formData = new FormData();
      formData.append("device_id", productiondata?.device_id);
      formData.append("device_type", productiondata?.device_type);
      formData.append("request_type", togglestatus ? 3 : 4);

      devicerefreshapi(formData, abortcontroller)
        .then(({ data }) => {
          setTimeout(() => {
            toviewcontroller();
            setbuttongreyid(0);
          }, 5000);
        })
        .catch((err) => {});
      // .finally(() => setloader(false));
    }
  };

  const loadingspinner = (requesttypedata) => {
    //controller data spinner and setting spinner

    if (abortcontroller) {
      abortcontroller.abort("error cancelled");
    }

    abortcontroller = new AbortController();

    if (requesttypedata === 1 || requesttypedata === 2) {
      // setloader(true);
      setloading(true);
      setTimeout(() => {
        setloading(false);
      }, 3000);
      let formData = new FormData();
      formData.append("controller_id", productiondata?.controller_id);
      formData.append("controller_type", productiondata?.controller_type);
      formData.append("request_type", requesttypedata === 1 ? 1 : 2);

      controllerrefreshapi(formData, abortcontroller)
        .then(({ data }) => {
          setTimeout(() => {
            toviewcontroller();
            setlocation(0);
          }, 5000);
        })
        .catch((err) => {});
      // .finally(() => setloader(false));
    } //device data spinner and setting spinner
    else if (requesttypedata === 3 || requesttypedata === 4) {
      // setloader(true);
      setspinnerindevice(true);
      setTimeout(() => {
        setspinnerindevice(false);
      }, 3000);
      let formData = new FormData();
      formData.append("device_id", productiondata?.device_id);
      formData.append("device_type", productiondata?.device_type);
      formData.append("request_type", requesttypedata === 3 ? 1 : 2);
      devicerefreshapi(formData, abortcontroller)
        .then(({ data }) => {
          setTimeout(() => {
            toviewcontroller();
            setlocation(0);
          }, 5000);
        })
        .catch((err) => {});
      // .finally(() => setloader(false));
    }
    //device location
    else if (requesttypedata === 5) {
      // setloader(true);

      let formData = new FormData();
      formData.append("device_id", productiondata?.device_id);
      formData.append("device_type", productiondata?.device_type);
      formData.append("request_type", 2);
      devicerefreshapi(formData, abortcontroller)
        .then(({ data }) => {
          setTimeout(() => {
            toviewcontroller();
            setlocation(0);
          }, 5000);
        })
        .catch((err) => {});
      // .finally(() => setloader(false));
    } //controller location
    else if (requesttypedata === 6) {
      // setloader(true);

      let formData = new FormData();
      formData.append("controller_id", productiondata?.controller_id);
      formData.append("controller_type", productiondata?.controller_type);
      formData.append("request_type", 2);
      controllerrefreshapi(formData, abortcontroller)
        .then(({ data }) => {
          setTimeout(() => {
            toviewcontroller();
            setlocation(0);
          }, 5000);
        })
        .catch((err) => {});
      // .finally(() => setloader(false));
    }
  };

  const getSimDropdown = () => {
    SimDropdownService().then(({ data }) => {
      if (data.status === 1) {
        let finalData = data.data.map((ele) => ({
          ...ele,
          label: ele.sim_number,
          value: ele.id,
        }));
        setSimList(finalData);
      } else {
        toast.error(data.msg);
      }
    });
  };
  useEffect(() => {
    toviewcontroller();
    getSimDropdown();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      toviewcontroller();
    }, 2 * 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Loader isLoader={loader} />
      {modal ? (
        <Changestatus
          show={modal}
          close={() => setmodal(false)}
          status={data?.status}
          data={data}
          funcall={() => toviewcontroller()}
        />
      ) : null}
      {amcchangeStatus.show && (
        <ConfirmationModal
          show={amcchangeStatus.show}
          close={() => {
            setAmcchangeStatus({ show: false, type: 0 });
          }}
          msg={`Are you sure want to change this ${
            amcchangeStatus.type === 1 ? "Controller" : "AMC"
          } status?`}
          handleOkFunction={() => {
            if (amcchangeStatus.type === 1) {
              setFieldValue("status", values.status === 1 ? 2 : 1);
            } else if (amcchangeStatus.type === 2) {
              setFieldValue("amc_status", values?.amc_status === 1 ? 2 : 1);
            }
            setAmcchangeStatus({ show: false, type: 0 });
          }}
        />
      )}
      {data?.history_lt?.length > 0 ? (
        <div className="productionhistry">
          <h3>History Details</h3>
          <div
            className={`${productionstyle.production_history_flex} status-div propadding`}
          >
            {data?.history_lt?.map((ele, index) => {
              return (
                <div className={productionstyle.production_history_div}>
                  <div className="status-div-flex">
                    <p
                      className={`${productionstyle.production_con_name} allsubhead`}
                    >
                      Tested By {ele?.username}
                    </p>
                    <p className="allsubhead">{ele?.updated_at}</p>
                  </div>
                  <div
                    className={
                      productiondata?.product_type === 1
                        ? "status-div-flex"
                        : "status_div_display"
                    }
                  >
                    <p className={productionstyle.production_con_name}>
                      {ele?.controller_name}
                    </p>
                    <p>
                      <span className="allsubhead">status : </span>
                      <span
                        className={
                          ele?.controller_status !== null ||
                          ele?.device_status !== null
                            ? ele?.controller_status === -1
                              ? productionstyle.production_status_deleted
                              : ele?.controller_status === 0
                              ? productionstyle.production_status_inactive
                              : ele?.controller_status === 1
                              ? productionstyle.production_status_on
                              : ele?.controller_status === 2
                              ? productionstyle.production_status_waiting
                              : ele?.controller_status === 3
                              ? productionstyle.production_status_off
                              : ele?.device_status === -1
                              ? productionstyle.production_status_deleted
                              : ele?.device_status === 0
                              ? productionstyle.production_status_inactive
                              : ele?.device_status === 1
                              ? productionstyle.production_status_on
                              : ele?.device_status === 2
                              ? productionstyle.production_status_waiting
                              : ele?.device_status === 3
                              ? productionstyle.production_status_off
                              : null
                            : null
                        }
                      >
                        {ele?.controller_status !== null ||
                        ele?.device_status !== null
                          ? ele?.controller_status === -1
                            ? "Deleted"
                            : ele?.controller_status === 0
                            ? "In Active"
                            : ele?.controller_status === 1
                            ? "Tested Ok"
                            : ele?.controller_status === 2
                            ? "Waiting for Test"
                            : ele?.controller_status === 3
                            ? "Fault"
                            : ele?.device_status === -1
                            ? "Deleted"
                            : ele?.device_status === 0
                            ? "In Active"
                            : ele?.device_status === 1
                            ? "Tested Ok"
                            : ele?.device_status === 2
                            ? "Waiting for Test"
                            : ele?.device_status === 3
                            ? "Fault"
                            : null
                          : null}
                      </span>
                    </p>
                  </div>
                  {ele?.msg ? (
                    <p>
                      <span className="allsubhead">Message : </span>
                      <span>{ele?.msg}</span>
                    </p>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
      <br />

      {productiondata?.product_type === 1 ? (
        <div>
          {
            <div className="controller-view">
              <div className="viewtop-div">
                <div className="editflex">
                  {data?.controller_name ? (
                    <div>
                      {data?.controller_name ? (
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={
                            <Tooltip>
                              <strong>{data?.controller_name}</strong>
                            </Tooltip>
                          }
                        >
                          <p className={motorstyle.motor_name}>
                            {data?.controller_name}
                          </p>
                        </OverlayTrigger>
                      ) : (
                        <p className={motorstyle.motor_name}>
                          {data?.controller_name}
                        </p>
                      )}
                    </div>
                  ) : null}
                </div>

                <div className="motor-btn">
                  {isloading && data?.controller_id === deviceid ? (
                    <img
                      src={ExportedImages.GREEN_SPINNER_GIF}
                      alt="tower"
                      className="controllerdetailpinner"
                      style={{ height: "23px" }}
                    />
                  ) : (
                    <img
                      src={ExportedImages.SPINNER_ICON}
                      alt="rotating-arrow"
                      className="controllerdetailpinner"
                      onClick={() => {
                        loadingspinner(type.requesttypeOne);
                        setdeviceid(data.controller_id);
                      }}
                    />
                  )}
                  {data?.tower ? (
                    <div className="productioncontower">
                      <img
                        src={
                          data?.tower >= 20 && data?.tower <= 39
                            ? ExportedImages.TOWER_ICON1
                            : data?.tower >= 40 && data?.tower <= 59
                            ? ExportedImages.TOWER_ICON2
                            : data?.tower >= 60 && data?.tower <= 79
                            ? ExportedImages.TOWER_ICON3
                            : data?.tower >= 80 && data?.tower <= 99
                            ? ExportedImages.TOWER_ICON4
                            : data?.tower === 100
                            ? ExportedImages.TOWER_ICON5
                            : ExportedImages.EMPTY_TOWER_ICON
                        }
                        alt="tower"
                        style={{
                          width: "30px",
                          right: "-275px",
                          marginBottom: "0px",
                        }}
                      />
                      &nbsp;&nbsp;
                      <span>{data?.tower}%</span>
                    </div>
                  ) : (
                    <img
                      src={ExportedImages.EMPTY_TOWER_ICON}
                      alt="tower"
                      style={{ width: "30px" }}
                    />
                  )}
                </div>

                <div className="motor-btn">
                  {data?.last_updated_by ? (
                    <p className="byproduction">By {data?.last_updated_by}</p>
                  ) : null}

                  {buttongreyid === 1 ? (
                    <Toggle
                      // id={index}
                      buttoncol={"grey"}
                      isChecked={
                        parseInt(data?.controller_status) === 1 ? true : false
                      }
                      handleChange={({ status }) => {
                        Changebutton(status);
                      }}
                    />
                  ) : data?.controller_code ? (
                    <Toggle
                      // id={index}
                      isChecked={
                        parseInt(data?.controller_status) === 1 ? true : false
                      }
                      handleChange={({ status }) => {
                        Changebutton(status);
                        setbuttongreyid(1);
                      }}
                    />
                  ) : null}
                </div>
              </div>

              {/* <div className="update-details"> */}
              <div className="date-update">
                <div className="update-text">
                  <p className="tank-header">last update</p>

                  <p className="tank-date">
                    {data?.controller_datetime
                      ? data?.controller_datetime
                      : null}
                  </p>
                </div>

                {/* <div className="status-update p0"> */}
                {/* LOADING IMAGE */}

                {/* END */}
                <div>
                  <p className="tank-header">status update</p>
                  <p className="tank-date">
                    {data?.status_change_time ? data?.status_change_time : null}
                  </p>
                  {/* </div> */}
                </div>
              </div>

              <p className="line motorLine One"></p>
              {data?.controller_code ? (
                <>
                  <div className="sub-details">
                    {data?.software_version != null ? (
                      parseInt(data?.software_version?.charAt(0)) == 1 ||
                      parseInt(data?.software_version?.charAt(0)) == 3 ? (
                        <div className="data-update">
                          {parseInt(data?.software_version?.charAt(0)) == 1 ||
                          parseInt(data?.software_version?.charAt(0)) == 3 ? (
                            <div className="motor-detail-sub-div bg-color-one">
                              <p className="volt-text-1">
                                <span>R</span>
                                {data?.r} V
                              </p>
                              <p className="volt-text-2">
                                <span>A</span>
                                {data?.rl1} A
                              </p>

                              <p className="tankpf">PF {data?.rpf1}</p>
                            </div>
                          ) : null}
                          {parseInt(data?.software_version?.charAt(0)) == 3 ? (
                            <>
                              <div className="motor-detail-sub-div  bg-color-two">
                                <p className="volt-text-1">
                                  <span>Y</span> {data?.y} V
                                </p>
                                <p className="volt-text-2">
                                  <span>A</span>
                                  {data?.yl2} A
                                </p>
                                <p className="tankpf">PF {data?.ypf2}</p>
                              </div>

                              <div className="motor-detail-sub-div  bg-color-three">
                                <p className="volt-text-1">
                                  <span>B</span> {data?.b} V
                                </p>
                                <p className="volt-text-2">
                                  <span>A</span>
                                  {data?.bl3} A
                                </p>
                                <p className="tankpf">PF {data?.bpf3}</p>
                              </div>
                            </>
                          ) : null}
                        </div>
                      ) : null
                    ) : null}
                    {/* <div>
                      <p>
                        <span className="tank-header">Hardware Version - </span>
                        <span className="tank-date">
                          {data?.hardware_version
                            ? `${data?.hardware_version}`
                            : null}
                        </span>
                      </p>
                      <p>
                        <span className="tank-header">Software Version - </span>
                        <span className="tank-date">
                          {data?.software_version
                            ? `${data?.software_version}`
                            : null}
                        </span>
                      </p>
                    </div> */}
                    {/* {data?.software_version != null ? (
                      parseInt(data?.software_version?.charAt(0)) == 1 ||
                      parseInt(data?.software_version?.charAt(0)) == 3 ? (
                        <div className="data-update">
                          {parseInt(data?.software_version?.charAt(0)) == 1 ||
                          parseInt(data?.software_version?.charAt(0)) == 3 ? (
                            <div className="motor-detail-sub-div bg-color-one">
                              <p className="volt-text-1">
                                <span>R</span>
                                {data?.r} V
                              </p>
                              <p className="volt-text-2">
                                <span>A</span>
                                {data?.rl1} A
                              </p>

                              <p className="tankpf">PF {data?.rpf1}</p>
                            </div>
                          ) : null}
                          {parseInt(data?.software_version?.charAt(0)) == 3 ? (
                            <>
                              <div className="motor-detail-sub-div  bg-color-two">
                                <p className="volt-text-1">
                                  <span>Y</span> {data?.y} V
                                </p>
                                <p className="volt-text-2">
                                  <span>A</span>
                                  {data?.yl2} A
                                </p>
                                <p className="tankpf">PF {data?.ypf2}</p>
                              </div>

                              <div className="motor-detail-sub-div  bg-color-three">
                                <p className="volt-text-1">
                                  <span>B</span> {data?.b} V
                                </p>
                                <p className="volt-text-2">
                                  <span>A</span>
                                  {data?.bl3} A
                                </p>
                                <p className="tankpf">PF {data?.bpf3}</p>
                              </div>
                            </>
                          ) : null}
                        </div>
                      ) : data?.r || data?.y || data?.b ? (
                        <div className="data-update">
                          {data?.r ? (
                            <div className="motor-detail-sub-div bg-color-one">
                              <p className="volt-text-1">
                                <span>R</span>
                                {data?.r} V
                              </p>
                              <p className="volt-text-2">
                                <span>A</span>
                                {data?.rl1} A
                              </p>

                              <p className="tankpf">PF {data?.rpf1}</p>
                            </div>
                          ) : null}
                          {data?.y ? (
                            <div className="motor-detail-sub-div  bg-color-two">
                              <p className="volt-text-1">
                                <span>Y</span> {data?.y} V
                              </p>
                              <p className="volt-text-2">
                                <span>A</span>
                                {data?.yl2} A
                              </p>
                              <p className="tankpf">PF {data?.ypf2}</p>
                            </div>
                          ) : null}
                          {data?.b ? (
                            <div className="motor-detail-sub-div  bg-color-three">
                              <p className="volt-text-1">
                                <span>B</span> {data?.b} V
                              </p>
                              <p className="volt-text-2">
                                <span>A</span>
                                {data?.bl3} A
                              </p>
                              <p className="tankpf">PF {data?.bpf3}</p>
                            </div>
                          ) : null}
                        </div>
                      ) : null
                    ) : data?.r || data?.y || data?.b ? (
                      <div className="data-update">
                        {data?.r ? (
                          <div className="motor-detail-sub-div bg-color-one">
                            <p className="volt-text-1">
                              <span>R</span>
                              {data?.r} V
                            </p>
                            <p className="volt-text-2">
                              <span>A</span>
                              {data?.rl1} A
                            </p>

                            <p className="tankpf">PF {data?.rpf1}</p>
                          </div>
                        ) : null}
                        {data?.y ? (
                          <div className="motor-detail-sub-div  bg-color-two">
                            <p className="volt-text-1">
                              <span>Y</span> {data?.y} V
                            </p>
                            <p className="volt-text-2">
                              <span>A</span>
                              {data?.yl2} A
                            </p>
                            <p className="tankpf">PF {data?.ypf2}</p>
                          </div>
                        ) : null}
                        {data?.b ? (
                          <div className="motor-detail-sub-div  bg-color-three">
                            <p className="volt-text-1">
                              <span>B</span> {data?.b} V
                            </p>
                            <p className="volt-text-2">
                              <span>A</span>
                              {data?.bl3} A
                            </p>
                            <p className="tankpf">PF {data?.bpf3}</p>
                          </div>
                        ) : null}
                      </div>
                    ) : null} */}

                    <div
                      className={productionstyle.production_con_right_detail}
                    >
                      <p>
                        <span className="tank-header">ID - </span>
                        <span className="tank-date">
                          {data?.controller_code}
                        </span>
                      </p>

                      {/* <div className="service">
                        <p className="tank-header">Location</p>
                        <img
                          src={place}
                          alt="place"
                          onClick={() => {
                            loadingspinner(type.requesttypeSix);
                            setdeviceid("");
                          }}
                        />
                      </div> */}

                      <div className="service">
                        <p className="tank-header">Location</p>
                        {locationtype === 1 ? (
                          <MdLocationOn className="locateapi" />
                        ) : (
                          <MdLocationOn
                            className="locate"
                            onClick={() => {
                              loadingspinner(type.requesttypeSix);
                              setdeviceid("");
                              setlocation(1);
                            }}
                          />
                        )}
                      </div>

                      <div>
                        <p>
                          <span className="tank-header"> Lat - </span>
                          <span className="tank-date">
                            {data?.latitude ? `${data?.latitude}‘N` : null}
                          </span>
                        </p>
                        <p>
                          <span className="tank-header">Long - </span>
                          <span className="tank-date">
                            {data?.longitude ? `${data?.longitude}‘E` : null}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p>
                          <span className="tank-header">
                            Hardware Version -
                          </span>
                          <span className="tank-date">
                            {data?.hardware_version
                              ? `${data?.hardware_version}`
                              : null}
                          </span>
                        </p>
                        <p>
                          <span className="tank-header">
                            Software Version -
                          </span>
                          <span className="tank-date">
                            {data?.software_version
                              ? `${data?.software_version}`
                              : null}
                          </span>
                        </p>
                      </div>

                      <div>
                        <button
                          type="button"
                          className="settings-btn"
                          onClick={() => {
                            loadingspinner(type.requesttypeTwo);
                            setdeviceid("");
                          }}
                        >
                          {isloading && deviceid == "" ? (
                            <img
                              src={ExportedImages.WHITE_SPINNER_GIF}
                              className="settingspinner"
                            />
                          ) : (
                            <ImSpinner11 />
                          )}
                          &nbsp;&nbsp;&nbsp;Settings
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className={productionstyle.production_con_status_flex}>
                    <button
                      type="button"
                      className={productionstyle.production_change_status_btn}
                      onClick={() => setmodal(true)}
                    >
                      Change Status
                    </button>
                    <p>
                      <span className="tank-header">Status - </span>
                      <span
                        className={
                          data?.status === -1
                            ? productionstyle.production_status_deleted
                            : data?.status === 0
                            ? productionstyle.production_status_inactive
                            : data?.status === 1
                            ? productionstyle.production_status_on
                            : data?.status === 2
                            ? productionstyle.production_status_waiting
                            : data?.status === 3
                            ? productionstyle.production_status_off
                            : null
                        }
                      >
                        {data?.status === -1
                          ? "Deleted"
                          : data?.status === 0
                          ? "InActive"
                          : data?.status === 1
                          ? "Tested Ok"
                          : data?.status === 2
                          ? "Waiting for Test"
                          : data?.status === 3
                          ? "Fault"
                          : null}
                      </span>
                    </p>
                    <button
                      type="button"
                      className={productionstyle.production_finish_btn}
                      // onClick={() => changesstatus()}
                      onClick={() => {
                        navigate("/production");
                      }}
                    >
                      Close
                    </button>
                  </div>
                  <br />
                </>
              ) : null}
            </div>
          }
        </div>
      ) : null}

      {productiondata?.device_type === 2 ? (
        <>
          <div
            className={`${productionstyle.device_details_view}
        ${commonstyle.cards_padding} 
        ${commonstyle.cards_border_radius}`}
          >
            <div className={`tank-level ${productionstyle.production_ID}`}>
              <div className={productionstyle.production_flow_spinner}>
                <div className="inputWidth">
                  {data?.device_name ? (
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-top">
                          <strong>{data?.device_name}</strong>
                        </Tooltip>
                      }
                    >
                      <p
                        className={`${motorstyle.device_name} 
                         ${motorstyle.device_name_flow_col}`}
                      >
                        {data?.device_name}
                      </p>
                    </OverlayTrigger>
                  ) : (
                    <p className={motorstyle.device_name}>
                      {data?.device_name}
                    </p>
                  )}
                </div>
                {data?.tower ? (
                  <div>
                    <img
                      src={
                        data?.tower >= 20 && data?.tower <= 39
                          ? ExportedImages.TOWER_ICON1
                          : data?.tower >= 40 && data?.tower <= 59
                          ? ExportedImages.TOWER_ICON2
                          : data?.tower >= 60 && data?.tower <= 79
                          ? ExportedImages.TOWER_ICON3
                          : data?.tower >= 80 && data?.tower <= 99
                          ? ExportedImages.TOWER_ICON4
                          : data?.tower === 100
                          ? ExportedImages.TOWER_ICON5
                          : ExportedImages.EMPTY_TOWER_ICON
                      }
                      alt="tower"
                      className={productionstyle.production_flow_tower}
                    />
                    <span
                      className={productionstyle.production_flow_percentage}
                    >
                      {data?.tower}%
                    </span>
                  </div>
                ) : (
                  <img
                    src={ExportedImages.EMPTY_TOWER_ICON}
                    alt="tower"
                    className={productionstyle.production_flow_tower}
                  />
                )}
              </div>
              <div>
                {data?.last_updated_by ? (
                  <p className="byproduction">By {data?.last_updated_by}</p>
                ) : null}
                <p className="tank-date">{data?.device_time}</p>
              </div>
            </div>

            <div className={`tank-level ${productionstyle.production_ID}`}>
              <div className={productionstyle.production_spinner_div_style}>
                <p className={`tank-usage ${productionstyle.production_ID}`}>
                  <span className="tank-header">ID - </span>
                  <span className="tank-date">{data?.device_code}</span>
                </p>
                {/* LOADING IMAGE */}
                {spinnerindevice && deviceid === data?.device_id ? (
                  <img
                    src={ExportedImages.GREEN_SPINNER_GIF}
                    alt="tower"
                    style={{ height: "23px" }}
                    className={productionstyle.production_spinner}
                  />
                ) : (
                  <img
                    src={ExportedImages.SPINNER_ICON}
                    alt="rotating-arrow"
                    onClick={() => {
                      loadingspinner(type.requesttypeThree);
                      setdeviceid(data?.device_id);
                    }}
                    className={productionstyle.production_spinner}
                  />
                )}
                {/* END */}
              </div>
              <p className={`tank-usage sasi ${productionstyle.production_ID}`}>
                <span className="tank-header">Usage Limit - </span>
                <span className="tank-date"> {data?.usage_limit}</span>&nbsp;
                <span className="tank-date">
                  {data?.usage_limit_status === 1 ? "off motor" : "on motor"}
                </span>
              </p>
            </div>

            <div className="sub-details">
              <div className="productionflex-data">
                <p className="tank-usage">
                  <span className="tank-header">Total usage - </span>
                  <span className="tank-date">{data?.total_usage}</span>
                </p>
                <p className="tank-usage">
                  <span className="tank-header">Daily Usage - </span>
                  <span className="tank-date">{data?.today_usage}</span>
                </p>
                <p className="tank-usage">
                  <span className="tank-header">Location </span>
                  {locationtype === 1 ? (
                    <MdLocationOn className="locateapi" />
                  ) : (
                    <MdLocationOn
                      className="locate"
                      onClick={() => {
                        loadingspinner(type.requesttypeFive);
                        setdeviceid("");
                        setlocation(1);
                      }}
                    />
                  )}
                </p>
                <p>
                  <span className="tank-header"> Lat - </span>
                  <span className="tank-date">
                    &nbsp;{data?.latitude ? `${data?.latitude} ‘N` : null}
                  </span>
                </p>
                <p>
                  <span className="tank-header"> Long - </span>
                  <span className="tank-date">
                    &nbsp;{data?.longitude ? `${data?.longitude} ‘E` : null}
                  </span>
                </p>

                <p className="tank-usage">
                  <span className="tank-header">Source - </span>
                  <span className="tank-date">
                    {data?.source !== null
                      ? data?.source == 0
                        ? "EB"
                        : "Battery"
                      : null}
                  </span>
                </p>
                {data?.source == 1 ? (
                  <>
                    <p className="tank-usage">
                      <span className="tank-header">Battery - </span>
                      <span className="tank-date"> {data?.battery} </span>
                    </p>
                    <p className="tank-usage">
                      <span className="tank-header">Solar Panel - </span>
                      <span className="tank-date">{data?.solar_panel}</span>
                    </p>
                  </>
                ) : null}
              </div>

              <div>
                <img
                  src={ExportedImages.FLOW_GIF}
                  alt="flowgif"
                  className={productionstyle.production_flow_gif}
                />
              </div>

              <div className="productionflex-data text-end">
                <p className="tank-usage">
                  <span className="tank-header">Flow Rate - </span>
                  <span className="tank-date"> {data?.flow_rate}</span>
                </p>
                <p className="tank-usage">
                  <span className="tank-header">Daily Update </span>
                  <span className="tank-date">
                    {data?.daily_update == null ? null : -data?.daily_update}
                  </span>
                </p>
                <p>
                  <span className="tank-header">Hardware version - </span>
                  <span className="tank-date">{data?.hardware_version}</span>
                </p>
                <p>
                  <span className="tank-header">Software version - </span>
                  <span className="tank-date">{data?.software_version}</span>
                </p>
                <p>
                  <button
                    type="button"
                    className="settings-btn"
                    onClick={() => {
                      loadingspinner(type.requesttypeFour);
                      setdeviceid("");
                    }}
                  >
                    {spinnerindevice && deviceid === "" ? (
                      <img
                        src={ExportedImages.WHITE_SPINNER_GIF}
                        className="settingspinner"
                      />
                    ) : (
                      <ImSpinner11 />
                    )}
                    <ImSpinner11 />
                    &nbsp;&nbsp;&nbsp;Settings
                  </button>
                </p>

                <p className="tank-usage">
                  <span className="tank-header">Liter/Pulse - </span>
                  <span className="tank-date"> {data?.lt_per_pulse}</span>
                </p>
              </div>
            </div>

            <div
              className={`${productionstyle.production_device_status_flex} ${productionstyle.production_flow_status}`}
            >
              <button
                type="button"
                className={productionstyle.production_device_cgangestatus_btn}
                onClick={() => setmodal(true)}
              >
                Change Status
              </button>

              <p className="tank-usage">
                <span className="tank-header">Status - </span>
                <span
                  className={
                    data?.status === -1
                      ? productionstyle.production_status_deleted
                      : data?.status === 0
                      ? productionstyle.production_status_inactive
                      : data?.status === 1
                      ? productionstyle.production_status_on
                      : data?.status === 2
                      ? productionstyle.production_status_waiting
                      : data?.status === 3
                      ? productionstyle.production_status_off
                      : null
                  }
                >
                  {data?.status === -1
                    ? "Deleted"
                    : data?.status === 0
                    ? "InActive"
                    : data?.status === 1
                    ? "Tested Ok"
                    : data?.status === 2
                    ? "Waiting for Test"
                    : data?.status === 3
                    ? "Fault"
                    : null}
                </span>
              </p>

              <button
                type="button"
                className={productionstyle.production_device_finish_btn}
                // onClick={() => changesstatus()}
                onClick={() => {
                  navigate("/production");
                }}
              >
                Close
              </button>
            </div>
          </div>
        </>
      ) : null}

      {productiondata?.device_type === 4 ? (
        <>
          <div
            className={`${productionstyle.device_details_view}
        ${commonstyle.cards_padding} 
        ${commonstyle.cards_border_radius}`}
          >
            {/* <div className="data-data  tank-bgcolor"> */}
            <div className="setting-top-bar">
              <div className="setting-sub-bar">
                {data?.device_name ? (
                  <div>
                    {data?.device_name ? (
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip>
                            <strong>{data?.device_name}</strong>
                          </Tooltip>
                        }
                      >
                        <p
                          className={`${motorstyle.device_name} 
                        ${motorstyle.device_name_valve_col}`}
                        >
                          {data?.device_name}
                        </p>
                      </OverlayTrigger>
                    ) : (
                      <p
                        className={`${motorstyle.device_name} 
                        ${motorstyle.device_name_valve_col}`}
                      >
                        {data?.device_name}
                      </p>
                    )}
                  </div>
                ) : null}
                {data?.tower ? (
                  <div className="productioncontower">
                    <img
                      src={
                        data?.tower >= 20 && data?.tower <= 39
                          ? ExportedImages.TOWER_ICON1
                          : data?.tower >= 40 && data?.tower <= 59
                          ? ExportedImages.TOWER_ICON2
                          : data?.tower >= 60 && data?.tower <= 79
                          ? ExportedImages.TOWER_ICON3
                          : data?.tower >= 80 && data?.tower <= 99
                          ? ExportedImages.TOWER_ICON4
                          : data?.tower === 100
                          ? ExportedImages.TOWER_ICON5
                          : ExportedImages.EMPTY_TOWER_ICON
                      }
                      alt="tower"
                      style={{ width: "30px" }}
                      className="lightTower"
                    />
                    &nbsp;&nbsp;
                    <span className={productionstyle.light_tower_percentage}>
                      {data?.tower}%
                    </span>
                  </div>
                ) : (
                  <img
                    src={ExportedImages.EMPTY_TOWER_ICON}
                    alt="tower"
                    style={{ width: "30px" }}
                  />
                )}
              </div>

              <div className="production_light_button">
                {data?.last_updated_by ? (
                  <p className="byproduction">By {data?.last_updated_by}</p>
                ) : null}
                <div className="edit-off">
                  {buttongreyid === 1 ? (
                    <Toggle
                      // id={index}
                      buttoncol={"grey"}
                      isChecked={data?.light_status == 1 ? true : false}
                      handleChange={({ status }) => {
                        Changebutton(status);
                      }}
                    />
                  ) : data?.device_code ? (
                    <Toggle
                      isChecked={data?.light_status == 1 ? true : false}
                      handleChange={({ status }) => {
                        Changebutton(status);
                        setbuttongreyid(1);
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="setting-top-bar setting-height">
              <p className="tank-usage d-grid pb-2">
                <span className="tank-header">last time - </span>
                <span className="tank-date">
                  {data?.device_time !== null && data?.device_time.includes("T")
                    ? moment(data?.device_time).format("DD-MM-YYYY HH:mm:ss")
                    : data?.device_time}
                </span>
              </p>
              <p>
                {/* LOADING IMAGE */}
                {spinnerindevice && deviceid === data?.device_id ? (
                  <img
                    src={ExportedImages.GREEN_SPINNER_GIF}
                    alt="tower"
                    className="tanpkinner data_spinner"
                    style={{ height: "23px" }}
                  />
                ) : (
                  <img
                    src={ExportedImages.SPINNER_ICON}
                    alt="rotating-arrow"
                    className="tanpkinner data_spinner"
                    onClick={() => {
                      loadingspinner(type.requesttypeThree);
                      setdeviceid(data?.device_id);
                    }}
                  />
                )}
                {/* END */}
                <p className="tank-usage d-grid">
                  <span className="tank-header">status time - </span>
                  <span className="tank-date">
                    {data?.on_off_time !== null
                      ? data?.on_off_time?.includes("T")
                        ? moment(data?.on_off_time).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )
                        : data?.on_off_time
                      : ""}
                  </span>
                </p>
              </p>
            </div>
            <div className="setting-top-bar">
              <div>
                <p className="tank-usage">
                  <span className="tank-header">ID - </span>
                  <span className="tank-date">{data?.device_code}</span>
                </p>
                <p className="tank-usage">
                  <span className="tank-header">Dim - </span>
                  <span className="tank-date">{data?.dim_level}% </span>
                </p>
                <p className="tank-usage">
                  <span className="tank-header">burning hour - </span>
                  <span className="tank-date">
                    <span>{data?.burning_hrs}</span>
                  </span>
                </p>
                <p className="tank-usage">
                  <span className="tank-header">Location </span>
                  &nbsp;
                  {locationtype === 1 ? (
                    <MdLocationOn className="locateapi" />
                  ) : (
                    <MdLocationOn
                      className="locate"
                      onClick={() => {
                        loadingspinner(type.requesttypeFive);
                        setdeviceid("");
                        setlocation(1);
                      }}
                    />
                  )}
                  &nbsp;
                </p>
                <p>
                  <span className="tank-header">Lat - </span>
                  <span className="tank-date">
                    {data?.latitude ? `${data?.latitude}‘N` : null}
                  </span>
                </p>
                <p>
                  <span className="tank-header">Long - </span>
                  <span className="tank-date">
                    {data?.longitude ? `${data?.longitude}‘E` : null}
                  </span>
                </p>
              </div>
              <div className={productionstyle.production_light_div}>
                <p className="tank-usage">
                  <span className="tank-header">volts - </span>
                  <span className="tank-date">
                    <span>{data?.volts}</span>
                  </span>
                </p>
                <p className="tank-usage">
                  <span className="tank-header">Amps - </span>
                  <span className="tank-date">{data?.amps}</span>
                </p>
                <p className="tank-usage">
                  <span className="tank-header">PF - </span>
                  <span className="tank-date">
                    <span>{data?.pf}</span>
                  </span>
                </p>
                <p className="tank-usage">
                  <span className="tank-header">software version - </span>
                  <span className="tank-date">{data?.software_version}</span>
                </p>
                <p className="tank-usage">
                  <span className="tank-header">hardware version - </span>
                  <span className="tank-date">
                    <span>{data?.hardware_version}</span>
                  </span>
                </p>
                <button
                  type="button"
                  className="settings-btn"
                  onClick={() => {
                    loadingspinner(type.requesttypeFour);
                    setdeviceid("");
                  }}
                >
                  {spinnerindevice && deviceid === "" ? (
                    <img
                      src={ExportedImages.WHITE_SPINNER_GIF}
                      className="settingspinner"
                    />
                  ) : (
                    <ImSpinner11 />
                  )}
                  &nbsp;&nbsp;&nbsp;Settings
                </button>
              </div>
            </div>
            <div className={productionstyle.production_device_status_flex}>
              <button
                type="button"
                className={productionstyle.production_device_cgangestatus_btn}
                onClick={() => setmodal(true)}
              >
                Change Status
              </button>

              <p className="tank-usage">
                <span className="tank-header">Status - </span>
                <span
                  className={
                    data?.status === -1
                      ? productionstyle.production_status_deleted
                      : data?.status === 0
                      ? productionstyle.production_status_inactive
                      : data?.status === 1
                      ? productionstyle.production_status_on
                      : data?.status === 2
                      ? productionstyle.production_status_waiting
                      : data?.status === 3
                      ? productionstyle.production_status_off
                      : null
                  }
                >
                  {data?.status === -1
                    ? "Deleted"
                    : data?.status === 0
                    ? "InActive"
                    : data?.status === 1
                    ? "Tested Ok"
                    : data?.status === 2
                    ? "Waiting for Test"
                    : data?.status === 3
                    ? "Fault"
                    : null}
                </span>
              </p>

              <button
                type="button"
                className={productionstyle.production_device_finish_btn}
                // onClick={() => changesstatus()}
                onClick={() => {
                  navigate("/production");
                }}
              >
                Close
              </button>
            </div>
            {/* </div> */}
          </div>
        </>
      ) : null}

      {productiondata?.device_type === 1 ? (
        <>
          <div
            className={`${productionstyle.device_details_view}
        ${commonstyle.cards_padding} 
        ${commonstyle.cards_border_radius}`}
          >
            <div className={productionstyle.production_tank_top_div}>
              <div className={productionstyle.production_tank_tower}>
                {data?.device_name ? (
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">
                        <strong> {data?.device_name}</strong>
                      </Tooltip>
                    }
                  >
                    <p
                      className={`${motorstyle.device_name} 
                      ${motorstyle.device_name_tank_col}`}
                    >
                      {data?.device_name}
                    </p>
                  </OverlayTrigger>
                ) : (
                  <p
                    className={`${motorstyle.device_name} 
                    ${motorstyle.device_name_tank_col}`}
                  >
                    {data?.device_name}
                  </p>
                )}
                <div className={productionstyle.production_tower}>
                  {data?.tower ? (
                    <>
                      <img
                        src={
                          data?.tower >= 20 && data?.tower <= 39
                            ? ExportedImages.TOWER_ICON1
                            : data?.tower >= 40 && data?.tower <= 59
                            ? ExportedImages.TOWER_ICON2
                            : data?.tower >= 60 && data?.tower <= 79
                            ? ExportedImages.TOWER_ICON3
                            : data?.tower >= 80 && data?.tower <= 99
                            ? ExportedImages.TOWER_ICON4
                            : data?.tower === 100
                            ? ExportedImages.TOWER_ICON5
                            : ExportedImages.EMPTY_TOWER_ICON
                        }
                        alt="tower"
                        className={
                          productionstyle.production_tank_tower_position
                        }
                      />
                      <span
                        className={productionstyle.production_tank_perentage}
                      >
                        {data?.tower}%
                      </span>
                    </>
                  ) : (
                    <img
                      src={ExportedImages.EMPTY_TOWER_ICON}
                      alt="tower"
                      className={productionstyle.production_tank_tower_position}
                    />
                  )}
                </div>
              </div>

              <div>
                {data?.last_updated_by ? (
                  <p className="byproduction">By {data?.last_updated_by}</p>
                ) : null}
                <p className="tank-date">{data?.device_time}</p>
              </div>
            </div>

            <div className="tank-level-data">
              <p className={productionstyle.production_ID}>
                <span className="tank-header">ID - </span>
                <span className="tank-date"> {data?.device_code} </span>
              </p>

              {/* LOADING IMAGE */}
              {spinnerindevice && deviceid === data.device_id ? (
                <img
                  src={ExportedImages.GREEN_SPINNER_GIF}
                  alt="tower"
                  className={productionstyle.production_tank_spinner}
                  style={{ height: "23px" }}
                />
              ) : (
                <img
                  src={ExportedImages.SPINNER_ICON}
                  alt="rotating-arrow"
                  style={{ height: "20px" }}
                  className={productionstyle.production_tank_spinner}
                  onClick={() => {
                    loadingspinner(type.requesttypeThree);
                    setdeviceid(data.device_id);
                  }}
                />
              )}
              {/* END */}

              <div className="btnWidth">
                <button
                  type="button"
                  className="settings-btn"
                  onClick={() => {
                    loadingspinner(type.requesttypeFour);
                    // setspinnerindevice(true);
                    setdeviceid("");
                  }}
                >
                  {spinnerindevice && deviceid === "" ? (
                    <img
                      src={ExportedImages.WHITE_SPINNER_GIF}
                      className="settingspinner"
                    />
                  ) : (
                    <ImSpinner11 />
                  )}
                  &nbsp;&nbsp;&nbsp;Settings
                </button>
              </div>
            </div>

            <div className="sub-details">
              <div className="w-40 detailsflex">
                <p>
                  <span className="tank-header">High Limit - </span>
                  <span className="tank-date">{data?.high_limit}</span>
                  <span className="tank-date">
                    {data?.high_limit_action === 0
                      ? "No action"
                      : data?.high_limit_action === 1
                      ? "Off Motor"
                      : data?.high_limit_action === 2
                      ? "On Motor"
                      : null}
                  </span>
                </p>

                <p className="d-inline-block ">
                  <span className="tank-header">Low Limit - </span>
                  <span className="tank-date">{data?.low_limit}</span>
                  <span className="tank-date">
                    {data?.low_limit_action === 0
                      ? "No action"
                      : data?.low_limit_action === 1
                      ? "Off Motor"
                      : data?.low_limit_action === 2
                      ? "On Motor"
                      : null}
                  </span>
                </p>
                <p>
                  <span className="tank-header">Tank Height - </span>
                  <span className="tank-date"> {data?.tank_height} ft</span>
                </p>

                <p>
                  <span className="tank-header">Location</span>
                  &nbsp;
                  {locationtype === 1 ? (
                    <MdLocationOn className="locateapi" />
                  ) : (
                    <MdLocationOn
                      className="locate"
                      onClick={() => {
                        loadingspinner(type.requesttypeFive);
                        setdeviceid("");
                        setlocation(1);
                      }}
                    />
                  )}
                </p>
                <p>
                  <span className="tank-header"> Lat - </span>
                  <span className="tank-date">
                    {data?.latitude ? `${data?.latitude}‘N` : null}
                  </span>
                </p>
                <p>
                  <span className="tank-header"> Long - </span>
                  <span className="tank-date">
                    {data?.longitude ? `${data?.longitude}‘E` : null}
                  </span>
                </p>

                <p>
                  <span className="tank-header">Water Quality</span>
                  <span className="tank-date">
                    -
                    {data?.source !== null
                      ? data?.source == 0
                        ? "EB"
                        : "TDS"
                      : null}
                  </span>
                </p>
                {data?.source == 1 ? (
                  <div
                    style={{
                      display: "flex",
                      gap: 10,
                      alignItems: "center",
                    }}
                  >
                    <p>
                      <span className="tank-header">TDS</span>
                      <span className="tank-date"> - {data?.battery}</span>
                    </p>
                    <p>
                      <span className="tank-header">PH</span>
                      <span className="tank-date"> - {data?.solar_panel}</span>
                    </p>
                  </div>
                ) : null}
                <p>
                  <span className="tank-header">Hardware version - </span>
                  <span className="tank-date">{data?.hardware_version}</span>
                </p>
                <p>
                  <span className="tank-header">Software version - </span>
                  <span className="tank-date">{data?.software_version}</span>
                </p>
              </div>
              <div className={productionstyle.production_tank_style}>
                <div>
                  <span className="tank-header">Tank Level-</span>
                  <span className="items-data">
                    {data?.tank_level !== null ? `${data?.tank_level}%` : null}
                  </span>
                </div>
                <div className={productionstyle.details_tank}>
                  {data?.tank_level !== null ? (
                    <>
                      <p className="waterper new_per">
                        {data?.tank_level !== null
                          ? `${data?.tank_level}%`
                          : null}
                      </p>
                      {data?.tank_level >= 1 && data?.tank_level <= 10 ? (
                        <img src={ExportedImages.WATER_TANK_10_GIF} />
                      ) : data?.tank_level > 10 && data?.tank_level <= 20 ? (
                        <img src={ExportedImages.WATER_TANK_20_GIF} />
                      ) : data?.tank_level > 20 && data?.tank_level <= 30 ? (
                        <img src={ExportedImages.WATER_TANK_30_GIF} />
                      ) : data?.tank_level > 30 && data?.tank_level <= 40 ? (
                        <img src={ExportedImages.WATER_TANK_40_GIF} />
                      ) : data?.tank_level > 40 && data?.tank_level <= 60 ? (
                        <img src={ExportedImages.WATER_TANK_50_GIF} />
                      ) : data?.tank_level > 60 && data?.tank_level <= 70 ? (
                        <img src={ExportedImages.WATER_TANK_60_GIF} />
                      ) : data?.tank_level > 70 && data?.tank_level <= 80 ? (
                        <img src={ExportedImages.WATER_TANK_70_GIF} />
                      ) : data?.tank_level > 80 && data?.tank_level <= 90 ? (
                        <img src={ExportedImages.WATER_TANK_80_GIF} />
                      ) : data?.tank_level > 90 && data?.tank_level <= 95 ? (
                        <img src={ExportedImages.WATER_TANK_90_GIF} />
                      ) : data?.tank_level > 95 && data?.tank_level <= 100 ? (
                        <img src={ExportedImages.WATER_TANK_100_GIF} />
                      ) : (
                        <img src={ExportedImages.EMPTY_WATER_TANK} />
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className={productionstyle.production_device_status_flex}>
              <button
                type="button"
                className={productionstyle.production_change_status_btn}
                onClick={() => setmodal(true)}
              >
                Change Status
              </button>

              <p>
                <span className="tank-header">Status - </span>
                <span
                  className={
                    data?.status === -1
                      ? productionstyle.production_status_deleted
                      : data?.status === 0
                      ? productionstyle.production_status_inactive
                      : data?.status === 1
                      ? productionstyle.production_status_on
                      : data?.status === 2
                      ? productionstyle.production_status_waiting
                      : data?.status === 3
                      ? productionstyle.production_status_off
                      : null
                  }
                >
                  {data?.status === -1
                    ? "Deleted"
                    : data?.status === 0
                    ? "InActive"
                    : data?.status === 1
                    ? "Tested Ok"
                    : data?.status === 2
                    ? "Waiting for Test"
                    : data?.status === 3
                    ? "Fault"
                    : null}
                </span>
              </p>

              <button
                type="button"
                className={productionstyle.production_finish_btn}
                // onClick={() => changesstatus()}
                onClick={() => {
                  navigate("/production");
                }}
              >
                Close
              </button>
            </div>
          </div>
        </>
      ) : null}

      {productiondata?.device_type === 3 ? (
        <div
          className={`${productionstyle.device_details_view}
        ${commonstyle.cards_padding} 
        ${commonstyle.cards_border_radius}`}
        >
          <div className="tank-level">
            <div className={productionstyle.production_valve_spinner}>
              {data?.device_name ? (
                <div>
                  {data?.device_name ? (
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip>
                          <strong>{data?.device_name}</strong>
                        </Tooltip>
                      }
                    >
                      <p
                        className={`${motorstyle.device_name} 
              ${motorstyle.device_name_valve_col}`}
                      >
                        {data?.device_name}
                      </p>
                    </OverlayTrigger>
                  ) : (
                    <p
                      className={`${motorstyle.device_name} 
                    ${motorstyle.device_name_valve_col}`}
                    >
                      {data?.device_name}
                    </p>
                  )}
                </div>
              ) : null}

              {data?.tower ? (
                <div className={productionstyle.production_tower}>
                  <img
                    src={
                      data?.tower >= 20 && data?.tower <= 39
                        ? ExportedImages.TOWER_ICON1
                        : data?.tower >= 40 && data?.tower <= 59
                        ? ExportedImages.TOWER_ICON2
                        : data?.tower >= 60 && data?.tower <= 79
                        ? ExportedImages.TOWER_ICON3
                        : data?.tower >= 80 && data?.tower <= 99
                        ? ExportedImages.TOWER_ICON4
                        : data?.tower === 100
                        ? ExportedImages.TOWER_ICON5
                        : ExportedImages.EMPTY_TOWER_ICON
                    }
                    alt="tower"
                    style={{ width: "30px" }}
                    className="lightTower"
                  />
                  &nbsp;&nbsp;
                  <span className={productionstyle.valve_perentage}>
                    {data?.tower}%
                  </span>
                </div>
              ) : (
                <img
                  src={ExportedImages.EMPTY_TOWER_ICON}
                  alt="tower"
                  className={productionstyle.empty_tower_valve}
                />
              )}
            </div>
            <div>
              {data?.last_updated_by ? (
                <p className="byproduction">By {data?.last_updated_by}</p>
              ) : null}
              <p className="tank-date">{data?.device_time}</p>
            </div>
          </div>

          <div className="tank-level-data">
            <p className="tank-usage">
              <span className="tank-header">ID - </span>
              <span className="tank-date">{data?.device_code}</span>
            </p>
            {/* LOADING IMAGE */}
            {spinnerindevice && data?.device_id === deviceid ? (
              <img
                src={ExportedImages.GREEN_SPINNER_GIF}
                alt="tower"
                className="valvespinner"
                style={{ height: "23px" }}
              />
            ) : (
              <img
                src={ExportedImages.SPINNER_ICON}
                alt="rotating-arrow"
                className="valvespinner"
                onClick={() => {
                  loadingspinner(type.requesttypeThree);
                  setdeviceid(data?.device_id);
                }}
              />
            )}
            {/* END */}
            <div>
              <span>{data?.operated_by}</span>&nbsp;
              {buttongreyid === 1 ? (
                <Toggle
                  buttoncol={"grey"}
                  isChecked={data?.valve_status === 1 ? true : false}
                  handleChange={({ status }) => {
                    Changebutton(status);
                  }}
                />
              ) : data?.device_code ? (
                <Toggle
                  isChecked={data?.valve_status === 1 ? true : false}
                  handleChange={({ status }) => {
                    Changebutton(status);
                    setbuttongreyid(1);
                  }}
                />
              ) : null}
            </div>
          </div>

          <div className="sub-details">
            <div>
              <p className="tank-usage">
                <span className="tank-header">Mode - </span>
                <span className="tank-date">
                  {data?.valve_mode === 2
                    ? "Weekly"
                    : data?.valve_mode === 1
                    ? "Daily"
                    : "Manual"}
                </span>
              </p>
              <p className="tank-usage">
                <span className="tank-header">Location - </span>
                &nbsp;
                {locationtype === 1 ? (
                  <MdLocationOn className="locateapi" />
                ) : (
                  <MdLocationOn
                    className="locate"
                    onClick={() => {
                      loadingspinner(type.requesttypeFive);
                      setdeviceid("");
                      setlocation(1);
                    }}
                  />
                )}
              </p>
              <p>
                <span className="tank-header">Lat - </span>
                <span className="tank-date">
                  {data?.latitude ? `${data?.latitude}‘N` : null}
                </span>
              </p>
              <p>
                <span className="tank-header">Long - </span>
                <span className="tank-date">
                  {data?.longitude ? `${data?.longitude}‘E` : null}
                </span>
              </p>

              <p>
                <span className="tank-header">Source</span>
                <span className="tank-date">
                  -
                  {data?.source !== null
                    ? data?.source == 0
                      ? "EB"
                      : "Battery"
                    : null}
                </span>
              </p>
              {data?.source == 1 ? (
                <>
                  <p>
                    <span className="tank-header">Battery</span>
                    <span className="tank-date"> - {data?.battery}</span>
                  </p>
                  <p>
                    <span className="tank-header">Solar panel</span>
                    <span className="tank-date"> - {data?.solar_panel}</span>
                  </p>
                </>
              ) : null}
              <p>
                <span className="tank-header">Software version - </span>
                <span className="tank-date">{data?.software_version}</span>
              </p>
              <p>
                <span className="tank-header">Hardware version - </span>
                <span className="tank-date">{data?.hardware_version}</span>
              </p>
              <button
                type="button"
                className={`${productionstyle.settings_btn} settings-btn`}
                onClick={() => {
                  loadingspinner(type.requesttypeFour);
                  setdeviceid("");
                }}
              >
                {spinnerindevice && deviceid === "" ? (
                  <img
                    src={ExportedImages.WHITE_SPINNER_GIF}
                    className="settingspinner"
                  />
                ) : (
                  <ImSpinner11 />
                )}
                &nbsp;&nbsp;&nbsp;Settings
              </button>
            </div>
            <div>
              <img
                src={ExportedImages.VALVE_IMG}
                alt="valve"
                className={productionstyle.production_valve}
              />
            </div>
          </div>
          <div className={productionstyle.production_device_status_flex}>
            <button
              type="button"
              className={productionstyle.production_change_status_btn}
              onClick={() => setmodal(true)}
            >
              Change Status
            </button>

            <p>
              <span className="tank-header">Status - </span>
              <span
                className={
                  data?.status === -1
                    ? productionstyle.production_status_deleted
                    : data?.status === 0
                    ? productionstyle.production_status_inactive
                    : data?.status === 1
                    ? productionstyle.production_status_on
                    : data?.status === 2
                    ? productionstyle.production_status_waiting
                    : data?.status === 3
                    ? productionstyle.production_status_off
                    : null
                }
              >
                {data?.status === -1
                  ? "Deleted"
                  : data?.status === 0
                  ? "InActive"
                  : data?.status === 1
                  ? "Tested Ok"
                  : data?.status === 2
                  ? "Waiting for Test"
                  : data?.status === 3
                  ? "Fault"
                  : null}
              </span>
            </p>

            <button
              type="button"
              className={productionstyle.production_finish_btn}
              // onClick={() => changesstatus()}
              onClick={() => {
                navigate("/production");
              }}
            >
              Close
            </button>
          </div>
        </div>
      ) : null}
      <br />

      <div className={productionstyle.amcContainer}>
        <div className="d-flex gap-2 justify-content-between align-items-center">
          <h3>AMC Report</h3>
          <img
            src={
              !editAMC
                ? ExportedImages.EDIT_ICON_BLUE
                : ExportedImages.SAVE_ICON_BLUE
            }
            alt="edit"
            onClick={() => {
              if (editAMC) {
                handleSubmit();
              } else {
                setEditAMC(!editAMC);
              }
            }}
            className="pointerCurser"
          />
        </div>
        <div className="row">
          <div
            className={`col-sm-12 col-md-6 col-lg-4 col-xl-3 px-3 ${productionstyle.acmDataBlock}`}
          >
            <p>
              <span>SIM</span> -
              {editAMC ? (
                <CommonSelect
                  options={SimList}
                  value={values.sim_id}
                  // placeholder={"Sim"}
                  onChange={(data) => {
                    setFieldValue("sim_id", data);
                  }}
                  inlinefield={true}
                  allowClear={false}
                />
              ) : (
                <span>{data?.sim_number}</span>
              )}
            </p>
            <p>
              <span>Validity (In Months)</span> -
              {editAMC ? (
                <CommonInput
                  value={values.validity}
                  maxLength={3}
                  isNumber={true}
                  onChange={(data) => {
                    if ((data <= 100 || data === "") && data !== "0") {
                      setFieldValue("validity", data);
                    }
                  }}
                  styles={{
                    borderColor:
                      errors.validity && touched.validity ? "red" : "",
                  }}
                  inlinefield={true}
                />
              ) : (
                <span>{data?.validity}</span>
              )}
            </p>
            <p>
              <span>Charges</span> -
              {editAMC ? (
                <CommonInput
                  maxLength={8}
                  value={values.amc_cost}
                  onChange={(data) => {
                    if ((data <= 99999.99 && data !== "0") || data == "") {
                      if (Float_Validation(data)) {
                        setFieldValue("amc_cost", data);
                      }
                    }
                  }}
                  styles={{
                    borderColor:
                      errors.amc_cost && touched.amc_cost ? "red" : "",
                  }}
                  inlinefield={true}
                />
              ) : (
                <span>{data?.amc_cost}</span>
              )}
            </p>
          </div>
          <div
            className={`col-sm-12 col-md-6 col-lg-4 col-xl-3 px-3 ${productionstyle.acmDataBlock}`}
          >
            <p>
              <span>SIM Started Date</span> -
              {editAMC ? (
                <CommonDateTimePicker
                  placeholder={""}
                  onChange={(data) => {
                    setFieldValue(
                      "amc_start_date",
                      dayjs(data).format("YYYY-MM-DD HH:mm:ss")
                    );
                  }}
                  formate={"DD-MM-YYYY"}
                  value={values.amc_start_date}
                  allowClear={false}
                  inlinefield={true}
                  styles={{
                    borderColor:
                      errors.amc_start_date && touched.amc_start_date
                        ? "red"
                        : "",
                  }}
                />
              ) : (
                <span>
                  {data?.amc_start_date
                    ? dayjs(data?.amc_start_date).format("YYYY-MM-DD")
                    : ""}
                </span>
              )}
            </p>
            <p>
              <span>No of Days to Blur</span> -
              {editAMC ? (
                <CommonInput
                  value={values.inactivate_delay}
                  maxLength={3}
                  isNumber={true}
                  onChange={(data) => {
                    if ((data <= 100 && data !== "0") || data === "") {
                      setFieldValue("inactivate_delay", data);
                    }
                  }}
                  // placeholder="No of Days to Blur"

                  inlinefield={true}
                />
              ) : (
                <span>
                  {data?.inactivate_delay}{" "}
                  {data?.inactivate_delay ? "days" : ""}
                </span>
              )}
            </p>
            <p>
              <span>SIM Expiry Date</span> -
              <span>
                {data?.amc_end_date
                  ? dayjs(data?.amc_end_date).format("YYYY-MM-DD")
                  : ""}
              </span>
            </p>
            {/* <p>
              <span>DeActivate Delay</span> -
              {editAMC ? (
                <CommonInput
                  value={values.deactivate_delay}
                  maxLength={3}
                  isNumber={true}
                  onChange={(data) => {
                    if ((data <= 100 && data !== "0") || data === "") {
                      setFieldValue("deactivate_delay", data);
                    }
                  }}
                  // placeholder="DeActivate Delay"
                  inlinefield={true}
                />
              ) : (
                <span>
                  {data?.deactivate_delay}{" "}
                  {data?.deactivate_delay ? "days" : ""}
                </span>
              )}
            </p> */}
          </div>
          <div
            className={`col-sm-12 col-md-6 col-lg-4 col-xl-3 px-3 ${productionstyle.acmDataBlock}`}
          >
            <p>
              <span>SIM Mobile No</span> -<span>{data?.sim_mobile_no}</span>
            </p>
            <p>
              <span>IMSI Number</span> -<span>{data?.imsi_number}</span>
            </p>
            <p>
              <span>Network</span> -<span>{data?.network}</span>
            </p>
          </div>
          <div
            className={`col-sm-12 col-md-6 col-lg-4 col-xl-3 px-3 ${productionstyle.acmDataBlock}`}
          >
            <p>
              <span>Controller Status</span> -
              {/* {editAMC ? (
                <CommonSwitch
                  checked={values.status === 1 ? true : false}
                  onChange={() => {
                    setAmcchangeStatus({ show: true, type: 1 });
                  }}
                  type={1}
                />
              ) : ( */}
              <span
                className={
                  data?.status === 1
                    ? productionstyle.activestatusTD
                    : productionstyle.statusTD
                }
              >
                {data?.status === 1 ? "Active" : "InActive"}
              </span>
              {/* )} */}
            </p>
            <p>
              <span>AMC Status</span> -
              {editAMC ? (
                <CommonSwitch
                  checked={values?.amc_status === 1 ? true : false}
                  onChange={() => {
                    setAmcchangeStatus({ show: true, type: 2 });
                  }}
                  type={2}
                />
              ) : (
                <span
                  className={
                    data?.amc_status === 1
                      ? productionstyle.activestatusTD
                      : productionstyle.statusTD
                  }
                >
                  {data?.amc_status === 1 ? "Activate" : "DeActivate"}
                </span>
              )}
            </p>
          </div>
          <div
            className={`col-sm-12 col-md-6 col-lg-6 ${productionstyle.acmDataBlock}`}
          >
            <p>
              <span>Display Content</span> -
              {editAMC ? (
                <textarea
                  type="text"
                  className="filterinput w-100 inlinefield"
                  value={values.amc_msg}
                  onChange={(data) => {
                    setFieldValue("amc_msg", data.target?.value);
                  }}
                  // placeholder="Content"
                  style={{ height: "55px" }}
                  maxLength={2000}
                />
              ) : (
                <span>{data?.amc_msg}</span>
              )}
            </p>
          </div>
          <div
            className={`col-sm-12 col-md-6 col-lg-6 ${productionstyle.acmDataBlock}`}
          >
            <p>
              <span>Remarks</span> -
              {editAMC ? (
                <textarea
                  type="text"
                  className="filterinput w-100 inlinefield"
                  value={values.remarks}
                  onChange={(data) => {
                    setFieldValue("remarks", data.target?.value);
                  }}
                  maxLength={2000}
                  // placeholder="Remarks"
                  style={{ height: "55px" }}
                />
              ) : (
                <span>{data?.remarks}</span>
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
