import { Switch } from "antd";
export default function CommonSwitch({ checked, onChange, type, color }) {
  return (
    <div
      className={color ? "" : "SwitchStyles"}
      onClick={(event) => {
        onChange(!checked);
        event.stopPropagation();
      }}
    >
      <Switch
        checked={checked ? true : false}
        checkedChildren={
          type === 3 ? "Open" : type === 1 ? "Active" : "Activated"
        }
        unCheckedChildren={
          type === 3 ? "Close" : type === 1 ? "InActive" : "DeActivated"
        }
        className="custom-style"
        style={{
          transform: "scale(1.1)",
          marginRight: 10,
          backgroundColor: color ?? "",
        }}
      />
    </div>
  );
}
