import { Tooltip } from "antd";
import { FindAvilable } from "../../Utilities/Methods";
import commonstyle from "../../Assets/css/CommonStyle.module.css";

export default function BlurComponent({
  deviceData,
  navigateFunc,
  blurData = null,
}) {
  return (
    <div
      onClick={() => {
        navigateFunc?.();
      }}
      className={
        (
          blurData
            ? blurData.show && blurData.id
            : FindAvilable(deviceData).show
        )
          ? "blurData"
          : "hideDiv"
      }
    >
      <div className="d-flex gap-3 justify-content-between align-items-start">
        <Tooltip
          title={
            (blurData ? blurData.name : FindAvilable(deviceData)?.data?.name) ||
            ""
          }
        >
          <p className={commonstyle.item_name}>
            {blurData ? blurData.name : FindAvilable(deviceData)?.data?.name}
          </p>
        </Tooltip>

        <p className="blurID">
          ID -{blurData ? blurData.id : FindAvilable(deviceData)?.data?.id}
        </p>
      </div>
      <div className="blurContant">
        <p className="expired_batch">AMC Expired</p>
        <span>
          {blurData
            ? blurData.amc_msg
            : FindAvilable(deviceData)?.data?.amc_msg}
        </span>
      </div>
    </div>
  );
}
