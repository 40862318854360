// import React, { useEffect, useState } from "react";
// import { Line } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
//   Filler,
// } from "chart.js";
// import { devicechart } from "../../../../Service/ApiService";
// import chartstyle from "../Charts/Chart.module.css";
// import { useSelector } from "react-redux";
// import moment from "moment";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
//   Filler
// );

// export default function TankLineChart({ tankchart }) {
//   const { ChartDateandTime } = useSelector((state) => state);
//   const [YAxisdata, setYAxisdata] = useState();
//   const [XAxisdata, setXAxisdata] = useState();
//   const [dubdata, setdubdata] = useState();
//   let CurrentDate = moment(new Date()).format("YYYY-MM-DD 00:00:00");
//   let BeforeSevenDays = moment(new Date())
//     .subtract(7, "d")
//     .format("YYYY-MM-DD 00:00:00");
//   useEffect(() => {
//     tankdata();
//   }, [tankchart]);
//   const tankdata = () => {
//     let TankformData = new FormData();
//     TankformData.append("controller_id", tankchart);
//     TankformData.append(
//       "from_date",
//       BeforeSevenDays
//     );
//     TankformData.append(
//       "to_date",
//      CurrentDate
//     );
//     TankformData.append("device_type", 1);
//     devicechart(TankformData)
//       .then(({ data }) => {
//         let XAxis = data?.data?.map((xdata) => {
//           let dataTwo = xdata?.tank_data?.map((k) => {
//             return k.datetime;
//           });
//           return dataTwo;
//         });
//         setXAxisdata(XAxis?.flat());
//         setdubdata(data);
//         let YAxis = data?.data?.map((ydata, index) => {
//           let dataOne = ydata?.tank_data?.map((tlevel) => {
//             return tlevel?.tank_level;
//           });
//           return {
//             data: dataOne,
//             label: ydata?.device_dt?.device_name,
//             borderColor:
//               index < 10
//                 ? `rgba(255, 99, ${index}9${index},0.8)`
//                 : `rgba(${index}2, ${index} , ${index}9,0.8)`,
//             backgroundColor:
//               index < 10
//                 ? `rgb(${index}${index}${index} 5 58 / 50%)`
//                 : `rgb(${index}2 5 58 / 50%)`,
//             // borderWidth: 4,
//             pointRadius: 3,
//             pointHoverRadius: 7,
//             // borderColor: `#a8${index}cc${index}`,
//             // backgroundColor: `#${index}af10${index}`,
//           };
//         });
//         setYAxisdata(YAxis);
//       })
//       .catch((err) => {});
//   };
//   return (
//     <>
//       {XAxisdata && YAxisdata ? (
//         <div className={chartstyle.chartbackground}>
//           <Line
//             data={{
//               labels: XAxisdata,
//               datasets: YAxisdata,
//             }}
//             options={{
//               responsive: true,
//               plugins: {
//                 legend: {
//                   display: true,
//                   position: "top",
//                   // align: "left",
//                 },
//                 title: {
//                   display: true,
//                   text: "Tank Device Level",
//                   font: { size: 20 },
//                 },
//               },
//               scales: {
//                 x: {
//                   grid: { display: true },

//                   title: {
//                     display: true,
//                     text: "Date and Time",
//                     font: { size: 20 },
//                     color: "green",
//                   },
//                 },
//                 y: {
//                   grid: { display: true },

//                   title: {
//                     display: true,
//                     text: "Tank Level",
//                     font: { size: 20 },
//                     color: "green",
//                   },
//                 },
//               },
//             }}
//           />
//         </div>
//       ) : null}{" "}
//     </>
//   );
// }

import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { devicechart } from "../../../../Service/ApiService";
import chartstyle from "../Charts/Chart.module.css";
import moment from "moment";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../../Loader";
import { toast } from "react-hot-toast";
import CommonDateTimePicker from "../../../CommonComponents/CommonDatePicker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const validate = Yup.object().shape({
  startDate: Yup.date().default(() => new Date()),
  endDate: Yup.date().min(
    Yup.ref("startDate"),
    "End date can't be before Start date"
  ),
});

export default function TankLineChart({ tankchart }) {
  const [YAxisdata, setYAxisdata] = useState();
  const [XAxisdata, setXAxisdata] = useState([]);
  const [dubdata, setdubdata] = useState();
  let [loader, setloader] = useState(false);
  const [DeviceData, setDeviceData] = useState([]);
  let CurrentDate = moment(new Date()).format("YYYY-MM-DD 00:00:00");
  let BeforeSevenDays = moment(new Date())
    .subtract(7, "d")
    .format("YYYY-MM-DD 00:00:00");

  const { values, errors, touched, setValues, handleSubmit } = useFormik({
    initialValues: {
      startDate: new Date(
        moment().subtract(7, "d").format("YYYY-MM-DD 00:00:00")
      ),
      endDate: new Date(),
    },
    validationSchema: validate,
    onSubmit: (values) => {
      tankdata();
    },
  });

  useEffect(() => {
    tankdata();
  }, [tankchart]);

  useEffect(() => {
    const interval = setInterval(() => {
      tankdata();
      setValues({
        startDate: new Date(
          moment().subtract(7, "d").format("YYYY-MM-DD 00:00:00")
        ),
        endDate: new Date(),
      });
    }, 2 * 60000);
    return () => clearInterval(interval);
  }, []);

  const tankdata = () => {
    setloader(true);
    let TankformData = new FormData();
    TankformData.append("controller_id", tankchart);
    TankformData.append(
      "from_date",
      moment(values.startDate).format("YYYY-MM-DD HH:mm:ss")
        ? moment(values.startDate).format("YYYY-MM-DD HH:mm:ss")
        : BeforeSevenDays
    );
    TankformData.append(
      "to_date",
      moment(values.endDate).format("YYYY-MM-DD HH:mm:ss")
        ? moment(values.endDate).format("YYYY-MM-DD HH:mm:ss")
        : CurrentDate
    );
    TankformData.append("device_type", 1);
    devicechart(TankformData)
      .then(({ data }) => {
        setDeviceData(data);
        let XAxis = data?.data?.map((xdata) => {
          let dataTwo = xdata?.tank_data?.map((k) => {
            return k.datetime;
          });
          return dataTwo;
        });
        // setXAxisdata(XAxis?.flat());
        if (XAxis?.length > 0) {
          setXAxisdata(XAxis.flat());
        } else {
          setXAxisdata(XAxis);
        }
        setdubdata(data);
        let YAxis = data?.data?.map((ydata, index) => {
          let dataOne = ydata?.tank_data?.map((tlevel) => {
            return tlevel?.tank_level;
          });
          return {
            data: dataOne,
            label: ydata?.device_dt?.device_name,
            borderColor:
              index < 10
                ? `rgba(255, 99, ${index}9${index},0.8)`
                : `rgba(${index}2, ${index} , ${index}9,0.8)`,

            backgroundColor:
              index < 10
                ? `rgb(${index}${index}${index} 5 58 / 50%)`
                : `rgb(${index}2 5 58 / 50%)`,
            // borderWidth: 4,
            pointRadius: 3,
            pointHoverRadius: 7,
            // borderColor: `#a8${index}cc${index}`,
            // backgroundColor: `#${index}af10${index}`,
          };
        });
        setYAxisdata(YAxis);
      })
      .catch((err) => {
        if (err?.response?.data?.detail?.[0]?.msg) {
          toast(err?.response?.data?.detail?.[0]?.msg, { type: "error" });
        } else if (err?.response?.data?.detail) {
          toast(err?.response?.data?.detail, { type: "error" });
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      })
      .finally(() => setloader(false));
  };

  const handleDeviceData = () => {
    let labels = XAxisdata;
    let FirstDevice = DeviceData?.data?.[0]?.tank_data?.map((ele) => {
      return ele;
    });
    let SecondDevice = DeviceData?.data?.[1]?.tank_data?.map((ele) => {
      return ele;
    });
    let finalarr = [FirstDevice, SecondDevice];
    var finalArray = [[], []];
    labels.map((ele) => {
      let count = 0;
      finalarr.map((element) => {
        let findData = element?.find((item) => item.datetime === ele);
        if (findData) {
          finalArray[count].push(findData?.tank_level);
          count = count + 1;
          return findData?.tank_level;
        } else {
          finalArray[count].push(0);
          count = count + 1;
          return 0;
        }
      });
    });
    return finalArray;
  };
  const checkinsData = {
    labels: XAxisdata,
    datasets: [
      {
        label: DeviceData?.data?.[0]?.device_dt?.device_name,
        value: 300,
        backgroundColor: "transparent",
        borderColor: "rgba(255,99,150,0.5)",
        borderWidth: 2,
        lineTension: 0.1,

        fill: true,
        // data: [20, 30, 40, 70, 100],
        data: handleDeviceData()?.[0],
      },
      {
        label: DeviceData?.data?.[1]?.device_dt?.device_name,
        backgroundColor: "transparent",
        borderColor: "rgba(77,99,150,0.5)",
        borderWidth: 2,
        fill: true,
        value: 20,
        labels: 20,
        // data: [60, 40],
        data: handleDeviceData()?.[1],
      },
    ],
  };
  const singledevice = {
    labels: XAxisdata,
    datasets: [
      {
        label: DeviceData?.data?.[0]?.device_dt?.device_name,
        value: 300,
        backgroundColor: "transparent",
        borderColor: "rgba(255,99,150,0.5)",
        borderWidth: 2,
        lineTension: 0.1,
        fill: true,
        data: handleDeviceData()?.[0],
      },
    ],
  };

  const avoidletters = (letters) => {
    var ch = String.fromCharCode(letters.which);
    if (!/[0-9]/.test(ch)) {
      letters.preventDefault();
    }
  };
  return (
    <>
      <Loader isLoader={loader} />
      {XAxisdata && YAxisdata ? (
        <div className={chartstyle.chartbackground}>
          <div className={chartstyle.chartdivflex}>
            <div className={chartstyle.date_div}>
              {/* <div className="Dates"> */}
              <div className="from_to_date">
                <label className={chartstyle.date_chart}>From</label>
                <div>
                  <CommonDateTimePicker
                    value={values?.startDate}
                    onChange={(date) => {
                      setValues({
                        ...values,
                        startDate: new Date(date),
                      });
                    }}
                    formate={"DD-MM-YYYY"}
                    disableDate={true}
                    isFuture={true}
                    allowClear={false}
                  />

                  {errors?.startDate && touched?.startDate ? (
                    <p className="inputerror">{errors?.startDate}</p>
                  ) : null}
                </div>
              </div>
              <div className="from_to_date">
                <label className={chartstyle.date_chart}>To</label>
                <div className={chartstyle.chart_to_date_error_width}>
                  <CommonDateTimePicker
                    value={values?.endDate}
                    onChange={(date) => {
                      setValues({
                        ...values,
                        endDate: new Date(date),
                      });
                    }}
                    formate={"DD-MM-YYYY"}
                    disableDate={true}
                    isFuture={true}
                    allowClear={false}
                  />

                  <br />
                  {errors?.endDate && touched?.endDate ? (
                    <p className="inputerror">{errors?.endDate}</p>
                  ) : null}
                </div>
              </div>
              {/* </div> */}
            </div>
            <button
              type="submit"
              className={chartstyle.submitbuttom}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
          <Line
            // data={{
            //   labels: XAxisdata,
            //   datasets: YAxisdata,
            // }}
            data={
              DeviceData?.data?.[1]?.device_dt?.device_name !== undefined
                ? checkinsData
                : singledevice
            }
            options={{
              responsive: true,
              plugins: {
                legend: {
                  display: true,
                  position: "top",
                  // align: "left",
                },
                title: {
                  display: true,
                  text: "Tank Device Level",
                  font: { size: 20 },
                },
              },
              scales: {
                x: {
                  grid: { display: true },

                  title: {
                    display: true,
                    text: "Date and Time",
                    font: { size: 20 },
                    color: "green",
                  },
                },
                y: {
                  grid: { display: true },

                  title: {
                    display: true,
                    text: "Tank Level",
                    font: { size: 20 },
                    color: "green",
                  },
                },
              },
            }}
          />
        </div>
      ) : null}{" "}
    </>
  );
}
