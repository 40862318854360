import React from "react";

import { Select } from "antd";
import classes from "./components.module.css";

export default function CommonSelect({
  placeholder,
  OtherClassName,
  value,
  options,
  onChange,
  styles,
  disabled = false,
  mode,
  allowClear = true,
  onKeyDown,
  optionFilterProp,
  onBlur = () => {},
  inlinefield = false,
  responsiveClassName,
}) {
  return (
    <Select
      mode={mode}
      allowClear={allowClear}
      showSearch={true}
      options={options}
      dropdownStyle={{ zIndex: 11000 }}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
      className={`${
        OtherClassName ? "searchInputClass" : classes.filterselectinput
      } 
      ${responsiveClassName ? "responsiveStyels" : ""}
      ${inlinefield ? "inlineSelect" : ""}
      `}
      onClick={(e) => {
        if (onKeyDown) {
          onKeyDown(e);
        }
      }}
      onBlur={onBlur}
      optionFilterProp={optionFilterProp || "label"}
      filterOption={(input, option) =>
        (option[optionFilterProp || "label"] ?? "")
          .toLocaleLowerCase()
          .includes(input.toLocaleLowerCase())
      }
      style={{
        ...styles,
        border: "none",
        background: disabled ? "rgb(216 216 216 / 44%)" : "#fff",
        // paddingLeft: "20px",
      }}

      // className={classes.inputBox}
    />
  );
}
