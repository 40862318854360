// AUTH IMAGES
import FORGOT_PWD_IMG from "../Assets/image/forgotpassword.png";
import PHOTONIC_LOGO_MAIN from "../Assets/image/photonic-logo.png";
import PHOTONIC_LOGO_SVG from "../Assets/image/photonic.svg";

import LOGIN_BANNER from "../Assets/image/login.png";
import CONTACT_US_CALL_ICON from "../Assets/image/phone-call.png";
import ENVELOP_ICON from "../Assets/image/envelope.png";
import WEB_ICON from "../Assets/image/web.png";
import HOMEPAGE_BANNER1 from "../Assets/image/HomepageBanner1.svg";
import HOMEPAGE_BANNER2 from "../Assets/image/HomepageBanner2.svg";
import HOMEPAGE_BANNER3 from "../Assets/image/HomepageBanner3.svg";
import USER_PROFILE_IMAGE from "../Assets/image/userimg.png";
// Action Icons
import EDIT_ICON_BLUE from "../Assets/image/edit.png";
import EDIT_ICON_BLACK from "../Assets/image/editblack.png";

import SAVE_ICON_BLUE from "../Assets/image/saved.png";
import EDIT_IMAGE from "../Assets/image/saveimg.png";

import DELETE_ICON_RED from "../Assets/image/deleteIcon.png";
import DELETE_ICON_WHITE from "../Assets/image/delete.png";

import FILTER_ICON from "../Assets/image/funnel.png";
import POWER_CONSUMPTION_ICON from "../Assets/image/powerConsumptionIcon.png";
import WATER_CONSUMPTION_ICON from "../Assets/image/waterconsumption.png";
import UPLOAD_ICON from "../Assets/image/upload.png";
import DOWNLOAD_ICON from "../Assets/image/download.png";
import MOTOR_ICON from "../Assets/image/motorD.png";
import MOTOR_BLACK_ICON from "../Assets/image/motor.png";
import MOTOR_WHITE_ICON from "../Assets/image/whitemotor.png";
import LIGHT_WHITE_ICON from "../Assets/image/colorlight.svg";
import LIGHT_BLACK_ICON from "../Assets/image/blacklight.svg";
import NOTIFICATION_ICON from "../Assets/image/bell.png";

import WARNING_IMAGE from "../Assets/image/warningImage.png";
import WARNING_ICON from "../Assets/image/warning.jpg";
import RIGHT_ARROW_ICON from "../Assets/image/right-arrow.png";
import CLOSE_ICON from "../Assets/image/close.png";

// TOWER ICONS

import TOWER_ICON1 from "../Assets/image/towerone.png";
import TOWER_ICON2 from "../Assets/image/towertwo.png";
import TOWER_ICON3 from "../Assets/image/towerthree.png";
import TOWER_ICON4 from "../Assets/image/towerfour.png";
import TOWER_ICON5 from "../Assets/image/towerfive.png";
import EMPTY_TOWER_ICON from "../Assets/image/emptytower.png";

import SPINNER_ICON from "../Assets/image/spine.png";
import VALVE_IMG from "../Assets/image/valve.png";
import USER_ICON from "../Assets/image/Alldetailsuser.png";
import MERGE_LINK_ICON from "../Assets/image/LinkIcon.png";
import SAVED_TICK_ICON from "../Assets/image/check-mark.png";
// GIFS

import GREEN_SPINNER_GIF from "../Assets/image/greenspinner.gif";
import WHITE_SPINNER_GIF from "../Assets/image/white spinner.gif";

import EMPTY_WATER_TANK from "../Assets/image/emptywatertank.png";
import WATER_TANK_10_GIF from "../Assets/image/tank10.gif";
import WATER_TANK_20_GIF from "../Assets/image/tank20.gif";
import WATER_TANK_30_GIF from "../Assets/image/tank30.gif";
import WATER_TANK_40_GIF from "../Assets/image/tank40.gif";
import WATER_TANK_50_GIF from "../Assets/image/tank50.gif";
import WATER_TANK_60_GIF from "../Assets/image/tank60.gif";
import WATER_TANK_70_GIF from "../Assets/image/tank70.gif";
import WATER_TANK_80_GIF from "../Assets/image/tank80.gif";
import WATER_TANK_90_GIF from "../Assets/image/tank90.gif";
import WATER_TANK_100_GIF from "../Assets/image/tank100.gif";

import FLOW_GIF from "../Assets/image/vecgif.gif";

// HEADER ICONS

import DASHBOARD_ICON from "../Assets/Icons/home.png";
import LIGHTS_ICON from "../Assets/Icons/light.png";
import USERS_ICON from "../Assets/Icons/user.png";
import GROUPS_ICON from "../Assets/Icons/groups.png";
import PRODUCTION_HISTORY_ICON from "../Assets/Icons/productionHistory.png";
import AMC_ICON from "../Assets/Icons/AMC.png";
import SIM_ICON from "../Assets/Icons/sim.png";
import PAYMENT_ICON from "../Assets/Icons/payment.png";
import SMS_ICON from "../Assets/Icons/SMS.png";
import DEVICE_ICON from "../Assets/Icons/device.png";
import RemoveIcon from "../Assets/image/remove.png";
import FileLoader from "../Assets/image/fileLoader.gif";

export const ExportedImages = {
  FileLoader,
  RemoveIcon,
  PHOTONIC_LOGO_MAIN,
  PHOTONIC_LOGO_SVG,
  LOGIN_BANNER,
  FORGOT_PWD_IMG,
  CONTACT_US_CALL_ICON,
  ENVELOP_ICON,
  WEB_ICON,
  HOMEPAGE_BANNER1,
  HOMEPAGE_BANNER2,
  HOMEPAGE_BANNER3,
  EDIT_ICON_BLUE,
  EDIT_ICON_BLACK,
  SAVE_ICON_BLUE,
  EDIT_IMAGE,
  DELETE_ICON_RED,
  DELETE_ICON_WHITE,
  FILTER_ICON,
  POWER_CONSUMPTION_ICON,
  WATER_CONSUMPTION_ICON,
  TOWER_ICON1,
  TOWER_ICON2,
  TOWER_ICON3,
  TOWER_ICON4,
  TOWER_ICON5,
  EMPTY_TOWER_ICON,
  SPINNER_ICON,
  VALVE_IMG,
  USER_PROFILE_IMAGE,
  UPLOAD_ICON,
  DOWNLOAD_ICON,
  USER_ICON,
  MERGE_LINK_ICON,
  SAVED_TICK_ICON,
  MOTOR_ICON,
  MOTOR_BLACK_ICON,
  MOTOR_WHITE_ICON,

  LIGHT_WHITE_ICON,
  LIGHT_BLACK_ICON,

  NOTIFICATION_ICON,

  WARNING_IMAGE,
  WARNING_ICON,
  RIGHT_ARROW_ICON,
  CLOSE_ICON,

  //   GIFS
  GREEN_SPINNER_GIF,
  WHITE_SPINNER_GIF,

  //   WATER TANK GIFS
  EMPTY_WATER_TANK,
  WATER_TANK_10_GIF,
  WATER_TANK_20_GIF,
  WATER_TANK_30_GIF,
  WATER_TANK_40_GIF,
  WATER_TANK_50_GIF,
  WATER_TANK_60_GIF,
  WATER_TANK_70_GIF,
  WATER_TANK_80_GIF,
  WATER_TANK_90_GIF,
  WATER_TANK_100_GIF,

  FLOW_GIF,

  // HEADER ICONS
  DASHBOARD_ICON,
  LIGHTS_ICON,
  USERS_ICON,
  GROUPS_ICON,
  PRODUCTION_HISTORY_ICON,
  AMC_ICON,
  SIM_ICON,
  PAYMENT_ICON,
  SMS_ICON,
  DEVICE_ICON,
};
