import { ConfigProvider, Tabs } from "antd";

export default function CommonTabs({ onChange, items, activeKey }) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            itemSelectedColor: "#db0048",
            inkBarColor: "#db0048",
            itemColor: "black",
            itemHoverColor: "#db0048",
            itemActiveColor: "#db0048",
            titleFontSize: 18,
            horizontalItemPadding: 0,
            fontFamily: "Nunito-bold-700",
            /* here iscardHeight your component tokens */
          },
        },
        token: {
          colorBorderSecondary: "#fff",
          fontFamily: "Nunito-semibold-600",
        },
      }}
    >
      <Tabs activeKey={activeKey} items={items} onChange={onChange} />
    </ConfigProvider>
  );
}
